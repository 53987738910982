@import '@aurora/shared-client/styles/_variables.pcss';

.lia-replies-header {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr repeat(1, auto) 1fr;
  grid-template-areas: 'left center right';
  margin-bottom: 20px;

  @media (--lia-breakpoint-down-sm) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.lia-replies-title {
  grid-area: center;
  font-weight: var(--lia-font-weight-md);
  font-size: var(--lia-bs-font-size-sm);
  font-family: var(--lia-bs-font-family-base);
  line-height: var(--lia-bs-line-height-base);
  color: var(--lia-bs-body-color);
  margin-bottom: 0;

  @media (--lia-breakpoint-down-sm) {
    grid-area: left;
  }
}

.lia-replies-sort-menu {
  grid-area: right;
  margin-left: auto;
}

.lia-pager {
  margin-bottom: 10px;
  padding-top: 5px;
}

.lia-read-only-warning {
  padding: 13px 15px;
  align-items: center;
  background-color: var(--lia-bs-white);
  margin: 5px 0 20px;
  border: 1px solid var(--lia-bs-info);
  border-left: 5px solid var(--lia-bs-info);

  &-icon {
    margin: 0 10px 5px 0;
  }

  @media (--lia-breakpoint-down-sm) {
    margin-left: 20px;
    margin-right: 20px;
  }
}
